import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import "../pages/style.scss";

export default props => {
  const post = props.data.wordpressPost;
  const { featured_media } = post;

  return (
    <Layout>
      <section className="hero is-light is-paddingless">
        <div className="hero-body is-paddingless">
          <div className="columns is-desktop is-centered">
            <div className="column is-two-thirds">
              <figure className="image is-3by1">
                <img
                  height={400}
                  width={700}
                  src={
                    featured_media
                      ? featured_media.localFile.childImageSharp.fixed.src
                      : "https://bulma.io/images/placeholders/1280x960.png"
                  }
                  alt="Blog"
                />
              </figure>
            </div>
          </div>
        </div>
      </section>
      <section className="section is-desktop post-section">
        <div className="columns is-desktop is-centered">
          <div className="column is-two-thirds is-centered">
            <article>
              <time dateTime="2016-1-1">{post.date}</time>
              <h1
                className="title"
                dangerouslySetInnerHTML={{ __html: post.title }}
              ></h1>
              <span>{post.author.name}</span>
              <div
                className="post-content"
                dangerouslySetInnerHTML={{ __html: post.content }}
              ></div>
            </article>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export const singlePostQuery = graphql`
  query singlePostQuery($id: String!) {
    wordpressPost(id: { eq: $id }) {
      slug
      status
      template
      format
      title
      date(formatString: "MMMM Do, YYYY")
      featured_media {
        localFile {
          childImageSharp {
            fixed(width: 700) {
              base64
              width
              height
              src
              srcSet
            }
          }
        }
      }
      author {
        name
        id
      }
      excerpt
      content
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`;
